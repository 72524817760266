    import { CSSProperties, useState } from "react";
    import ContentCopyIcon from '@mui/icons-material/ContentCopy';
    import styles from './Input.module.css';
    type SetError = React.Dispatch<React.SetStateAction<string | null>>

    interface InputProps {
        // setClaimContext: SetClaimContext
        setError?: SetError;
        trackValue?: (value: string) => void;
        error?: string | null;
        name?: string;
        trackClick?: (value: string) => void;
        value?: string;
        readonly?: boolean
        overwriteStyle?: CSSProperties;
        copyButton?: boolean;
        area?: boolean;
        disabled?: boolean;
        backgroundColor?: string;
        // validate: (value: string) => string | null;
    }

    export const Input = (props: InputProps) => {
        const [value, setValue] = useState(props.value ?? '');

        const outerStyle: CSSProperties = {
            // height: "2.5rem",
            display: "flex",
            alignItems: "center",
            position: "relative",
            alignContent: "center",
            fontSize: "1rem"
        };

        // const placeholder = {
        //     ':placeholder': {
        //         color: "#B0B0B0",
        //     }
        // };


        const inputStyle = {
            paddingBottom: "0.9rem",
            paddingTop: "0.9rem",
            // background: "#1C1B23",
            background: props.backgroundColor ?? "#060A0D",
            borderRadius: "0.4rem",
            width: "100%",
            height: "100%",
            color: "white",
            border: "none",
            fontWeight: 400,
            paddingLeft: "0.8rem",
            fontSize: "1rem",
            ':placeholder': {
                color: "#B0B0B0",
            }
        };


        return (
            <div>
                <div style={{position: "absolute", fontWeight: 400,  zIndex: 1, marginLeft: "0.8rem", marginTop: "-0.6rem", fontSize: "0.9rem", color: "#B0B0B0", opacity: (value ? 1 : 0)}} className={styles.f}>
                    {props.name}
                </div>
                <div style={outerStyle}>
                    {props.area && <textarea disabled={props.disabled} readOnly={props.readonly} value={props.value} placeholder={props.name} style={{...inputStyle, ...props.overwriteStyle}} onChange={(v) => {setValue(v.target.value); if (props.trackValue) { props.trackValue(v.target.value) }}}  />}
                    {!props.area && <input className={styles.innerInput} disabled={props.disabled} readOnly={props.readonly} value={props.value} placeholder={props.name} style={{...inputStyle, ...props.overwriteStyle}} type='text' onChange={(v) => {setValue(v.target.value); if (props.trackValue) { props.trackValue(v.target.value) }}} />}
                    {(props.copyButton && <button disabled={props.disabled} className={styles.addToClipboardButton} onClick={(e) => navigator.clipboard.writeText(value ?? props.value)}><ContentCopyIcon style={{color: "white", width: "1.3rem", height: "1.3rem"}} /></button>)}
                </div>
                {props.error && <p>{props.error}</p>}
            </div>
        );
    }