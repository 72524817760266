export const Header = () => {
    return (
        <div className="header ng-star-inserted" style={{background: "black"}}>
        <div className="d-flex justify-content-between align-items-center w-100 menu-header">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center w-100 menu-box"><a className="cursor-pointer d-flex align-items-center" href="https://sale.starchain.dev/en"><img className="starchain-logo" alt="StarChain" src="https://joinstarchain.com/wp-content/uploads/2024/08/logo.webp" /></a>
              <div  className="menu-item d-flex align-items-center"><a title="token" href="https://sale.starchain.dev/en/#token" className="ng-star-inserted __mPS2id _mPS2id-h">How It Works </a><a title="roadmap" href="https://sale.starchain.dev/en/#roadmap" className="ng-star-inserted __mPS2id _mPS2id-h">Roadmap </a></div>
               <a href="https://sale.starchain.dev/en"><div className="btn btn-primary d-flex align-items-center font-16 ms-4 ng-star-inserted">Join Sale</div></a>
              </div>
            </div>
          </div>
        </div>
    );
}