import { createAppKit } from '@reown/appkit'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { mainnet } from '@reown/appkit/networks'
import React from 'react'
import { useAppKit, useAppKitAccount } from '@reown/appkit/react'

const ethersAdapter = new EthersAdapter()

const projectId = '681cdbc373677cf86acd7ae2e9cc50a1'


const metadata = {
  name: 'strc',
  description: 'AppKit Example',
  url: 'https://app.starchain.dev', // origin must match your domain & subdomain
  icons: ['https://assets.reown.com/reown-profile-pic.png']
}

// 4. Create Ethers config
const modal = createAppKit({
  adapters: [ethersAdapter],
  networks: [mainnet],
  metadata,
  projectId,
  features: {
    analytics: true // Optional - defaults to your Cloud configuration
  },
  
  // showWallets
})

// modal.ses

export const WalletConnect = () => {
    const a = React.createElement('w3m-button', { balance: false });
    // a.props.
    return<>{a}</> 
}