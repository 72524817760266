// GatedFunctionality.tsx
import React, { useState } from "react";
import styles from "./GatedFunctionality.module.css";
import { Button } from "./Button";
import { Input } from "./Input";

interface GatedFunctionalityProps {
  applicationUrl: string;
}

export const GatedFunctionality: React.FC<GatedFunctionalityProps> = ({ applicationUrl }) => {
    const [error, setError] = useState<string | null>(null)

  return (
    <div className={styles.container}>
      {/* Access Code Card */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          Use access code
        </div>
        <div className={styles.cardContent}>
          <Input name="Enter Access Code" />
        </div>
        <div className={styles.buttonContainer}>
          <Button name="Enter" trackClick={() => setError("Enter Valid Access Code")} />
        </div>
        {(error &&<div className={styles.error}>
          {error}
        </div>)}
      </div>

      {/* Apply Now Card */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          Don’t have an access code? / Apply now
        </div>
        <div className={styles.cardContentGrey}>
            Please apply using the Link below to receive access.
        </div>
        <div className={styles.buttonContainer}>
          <Button trackClick={() => window.open(applicationUrl, '_blank')?.focus()} name="Apply Now"  />
        </div>
      </div>
    </div>
  );
};
