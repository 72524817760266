import react from 'react';
import './DownloadPopup.css';
import { IDataset } from '../DataStoreExplorer/DataStoreExplorer';
import { Button } from '../functions/Button';

const FTPDownload = (props: { dataset: IDataset }) => {
    return (
    <div className='ftp-download download-popup-card'>
        <div className='download-row download-row-fixed download-popup-card-title'>
            FTP-Access
        </div>
        <div className='download-row download-row-fixed '>
            <div className='download-row-left'>
                FTP-IP
            </div>
            <div className='download-row-right'>
                {props.dataset.ftpHostName}
            </div>
        </div>
        <div className='download-row download-row-fixed'>
            <div className='download-row-left'>
                FTP-Hostname
            </div>
            <div className='download-row-right'>
                {props.dataset.ftpHostName}
            </div>
        </div>
        <div className='download-row download-row-fixed '>
            <div className='download-row-left'>
                FTP-Username
            </div>
            <div className='download-row-right'>
                {props.dataset.ftpUsername}
            </div>
        </div>
        <div className='download-row download-row-fixed'>
            <div className='download-row-left'>
                FTP-Password
            </div>
            <div className='download-row-right'>
                {props.dataset.ftpPassword}
            </div>
        </div>
        <div className='download-row download-row-fixed'>
            <div className='download-row-left'>
                FTP-Port
            </div>
            <div className='download-row-right'>
                {props.dataset.ftpPort}
            </div>
        </div>
        <div className='download-row download-row-fixed'>
            <div className='download-row-left'>
                Recommended FTP-Clients
            </div>
            <div className='download-row-right'>
                <a href="https://www.smartftp.com/en-us/" className='purple-link' target={"_blank"}>SmartFTP</a>  or  <a className='purple-link' href='https://filezilla-project.org/' target={"_blank"}>FileZilla</a>
            </div>
        </div>
    </div>
    )
}

const BrowserDownload = (props: { downloadUrl: string | undefined }) => {
    return (
        <div className='download-popup-card'>
            <div className='download-row download-row-fixed download-popup-card-title'>
                Browser Download (easy)
            </div>
            <div className='download-row download-popup-card-lower'>
                <div className='download-popup-card-text'>
                    Small downloads can be done directly in the browser without any need for FTP (Coming Soon)
                </div>
                <div className='download'>
                    <a href={props.downloadUrl} download={props.downloadUrl}>
                        
                        <Button disabled={props.downloadUrl === undefined} name={"Download"}  />
                {/* <button className={'download-button ' + (props.downloadUrl ? '' : 'download-button-inactive')}>
                <div>Download</div>
                {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-download-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
                {/* </button> */}
                </a>
            </div>
            </div>
        </div>
    )
}

const Troubleshooting = () => {
    return (
        <div className='download-popup-card'>
        <div className='download-row download-row-fixed download-popup-card-title'>
            Troubleshooting
        </div>
        <div className='download-row download-popup-card-lower'>
            <div className='download-popup-card-text'>
                Learn how to use a FTP-Client to connect to a server and download data in bulk.
            </div>
            <div className='download'>
                <Button name={"Get Help"} trackClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")} />
            {/* <button className='download-button' style={{cursor: "pointer"}} onClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")}><div>Get Help</div> */}
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-download-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
            {/* </button> */}
        </div>
        </div>
    </div>
    )
}

export const DownloadPopup = (props: {dataset: IDataset}) => {
    return (
        <div className='download-popup'>
            <div className='download-popup-title'>
                Download: {props.dataset.specification.name}
            </div>
            <FTPDownload dataset={props.dataset} />
            <div className='download-popup-lower'>
                <BrowserDownload downloadUrl={props.dataset.downloadUrl} />
                <Troubleshooting />
            </div>
        </div>
    )
}
