import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../App";
import { WalletConnect } from "./WalletConnect/WalletConntect";
import styles from './NavBar.module.css'
import { Close, Menu } from "@mui/icons-material";

export const NavBarDesktop = () => {
    return (
        <div className={styles.desktopOuterNav}>
            <div className="desktop-logo">
                <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/image%2013.png?alt=media&token=44c26d48-051e-4445-8534-ea928b6530ab" />
            </div>
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div className="desktop-inner-nav">
                    {routes.filter(r => !r.hidden).map(r => <NavBarItem name={r.name} imageUrl={r.imageUrl ?? ""} route={r.path} comingSoon={r.comingSoon} bigImage={r.bigImage} />)}
                </div>
                <div style={{marginBottom: "2.5rem"}}>
                    <WalletConnect />
                </div>
            </div>
        </div>
    )
}

export const NavBarMobile = (props: {navOpen: boolean, setNavOpen: (open: boolean) => void}) => {
    return (
        <div className={styles.mobileOuterNav}>
            <div className="mobile-logo">
                <a onClick={() => props.setNavOpen(false)}><Close /></a>
                <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/image%2013.png?alt=media&token=44c26d48-051e-4445-8534-ea928b6530ab" />
            </div>
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                <div className="mobile-inner-nav">
                    {routes.filter(r => !r.hidden).map(r => <NavBarItem close={() => props.setNavOpen(false)} name={r.name} imageUrl={r.imageUrl ?? ""} route={r.path} comingSoon={r.comingSoon} bigImage={r.bigImage} />)}
                </div>
                <div style={{marginBottom: "8rem"}}>
                    <WalletConnect />
                </div>
            </div>
        </div>
    )
}

export const TopNavMobile = (props: {navOpen: boolean, setNavOpen: (open: boolean) => void}) => {
    return <div className={styles.topNav}>
        <div className={styles.topNavLeft}>
            <a onClick={() => props.setNavOpen(true)}><Menu /></a>
            <img className={styles.topNavImg} src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/image%2013.png?alt=media&token=44c26d48-051e-4445-8534-ea928b6530ab" />
        </div>
        <div className={styles.topNavRight}>
            <button onClick={() => {window.open("https://sale.starchain.dev", "_blank")}}>Join Sale</button>
            {/* <WalletConnect /> */}
        </div>
    </div>
}

// export const NavBarMobile = () => {
//     // const a = useWindowSize()
//     return (
//         <div className="mobile-outer-nav">
//             <div className="mobile-logo">
//                 <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/image%2013.png?alt=media&token=44c26d48-051e-4445-8534-ea928b6530ab" />
//             </div>
//             <div className="mobile-inner-nav">
//                 {routes.filter(r => !r.hidden).map(r => <NavBarItem name={r.name} imageUrl={r.imageUrl ?? ""} route={r.path} comingSoon={r.comingSoon} bigImage={r.bigImage} />)}
//             </div>
//             {/* <WalletConnect /> */}
//         </div>
//     )
// }


interface NavBarItemProps {
    name: string;
    route: string;
    imageUrl: string;
    comingSoon?: boolean;
    bigImage?: boolean;
    close?: () => void;
}

export const NavBarItem = (props: NavBarItemProps) => {
    console.error(props)
    if (props.comingSoon) {
        return (
            <div style={{color: "gray"}}>
                <div className="nav-item">
                <img src={props.imageUrl} className={props.bigImage ? "nav-big-img" : ""} />
                <div className="desktop-nav-item-title">{props.name}</div>
                <div>
                <div style={{ marginLeft: "0.5rem", right: 0, marginTop: "-1.1rem", fontSize: "0.8rem", color: "white", background: "#8A46FF", borderRadius: "0.8rem", padding: "0.2rem 0.4rem"}}>
                    Soon
                </div>
                </div>
                </div>
            </div>
        )
    }
    return (
        <div>

        <NavLink to={(props.comingSoon ? "/" : props.route)} className={({ isActive }) =>
        [
            "nav-item",
          isActive ? "nav-item-selected" : "",
        ].join(" ")
      }>
            {/* <div> */}
                <img src={props.imageUrl} />
            {/* </div> */}
            <div onClick={props.close} className="desktop-nav-item-title">{props.name}</div>
        </NavLink>
        </div>
    )
}
