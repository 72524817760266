import './Footer.css';

export const Footer = () => {
    return (
        <section className="community-wrapper">
            <div className="container-fluid feature py-0">
                <div className="container">
                    <h2 className="section-title">Meet the worldwide community</h2>
                    <p className="section-desc">Join a fast-growing community of
                        developers and innovators connected all over the world, building the new era of scientific data.</p>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6 card-block">
                            <div className="card sm blured"><a href="https://t.me/StarChainANN" target="_blank"><img decoding="async" src="https://joinstarchain.com/wp-content/uploads/2024/08/send.svg" alt="Announcements" className="icon" /></a>
                                <div className="card-content">
                                    <h3>Announcements</h3>
                                    <p className="light mb-0">Join our announcements group
                                        to stay up to date with the progress of StarChain..</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 card-block">
                            <div className="card sm blured"><a href="https://t.me/StarChainSTRC" target="_blank"><img decoding="async" src="https://joinstarchain.com/wp-content/uploads/2024/08/send.svg" alt="Community Chat" className="icon" /></a>
                                <div className="card-content">
                                    <h3>Community Chat</h3>
                                    <p className="light mb-0">Ask questions and chat with
                                        the community on Telegram.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 card-block">
                            <div className="card sm blured"><a href="https://x.com/starchaindev" target="_blank"><img decoding="async" src="https://joinstarchain.com/wp-content/uploads/2024/08/twitter-circle.svg" alt="X (former Twitter)" className="icon" /></a>
                                <div className="card-content">
                                    <h3>X (former Twitter)</h3>
                                    <p className="light mb-0">Follow @StarChainDev on X to
                                        join our community and learn more about the ecosystem.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 card-block">
                            <div className="card sm blured"><a href="https://starchain.dev/linkedin" target="_blank"><img decoding="async" src="https://joinstarchain.com/wp-content/uploads/2024/08/linked-circle.svg" alt="Linked in" className="icon" /></a>
                                <div className="card-content">
                                    <h3>Linkedin</h3>
                                    <p className="light mb-0">Follow StarChainDev on
                                        Linkedin to find our open positions and learn more about the team..</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 card-block">
                            <div className="card sm blured"><a href="https://forms.gle/qNSLzrDcPQBKbjpq5" target="_blank"><img decoding="async" src="https://joinstarchain.com/wp-content/uploads/2024/08/developer-chat.svg" alt="Developer Chat" className="icon" /></a>
                                <div className="card-content">
                                    <h3>Developer Chat</h3>
                                    <p className="light mb-0">Have technical questions
                                        about StarChain’s tools? Ask a developer on the researcher discord.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}