import React, { ReactNode, useState } from 'react';
import { GatedFunctionality } from '../functions/GatedFunctionality';

export const DataStore = () => {
    const DataStoreTitle = () => {
        return <h1 className='page-title'>My <span className="gradient">DataStore</span></h1>
    }
    return (
        <div>
            {DataStoreTitle()}
            <GatedFunctionality applicationUrl={''} />
        </div>
    )
}
