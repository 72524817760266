import { useEffect, useState } from "react";
import { MyPopup } from "../DataStoreExplorer/DataStoreExplorer";
import { IUpload, SpecificationPopup } from "../UploadPopup/UploadPopup";
import './MyUploads.css'
import { Button } from "../functions/Button";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAppKitAccount } from "@reown/appkit/react";
import { WalletGated } from "../UploadPopup/WalletGated";
import { initializeApp } from "firebase/app";
import { UploadDataPopup } from "../UploadDataPopup/UploadDataPopup";
import { uploadHost } from "../../App";
import { TailSpin } from 'react-loader-spinner';
import { CircularProgress } from "@mui/material";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-teETwqkUxOM27zgawA8AsnQC2yvcraI",
    authDomain: "starchain-app.firebaseapp.com",
    projectId: "starchain-app",
    storageBucket: "starchain-app.appspot.com",
    messagingSenderId: "173972643442",
    appId: "1:173972643442:web:fdd9ce39f0cd8670d02ceb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export enum UploadState {
    Draft,
    ReviewPending,
    Refused,
    Uploaded,
  }
  
  
  interface IUploader {
    password: string;
    walletAddress: string;
  }

// interface IUpload {
//     name: string;
//   state: UploadState;
//   requestedTimestamp: number;
//   folderName: string;
//   walletAddress: string;
//   id: string;
//   tags: string[]
// }

// const Upload = (props: {upload: IUpload, openModal: (upload: IUpload) => void}) => {
//     return  (
//     <div className="upload">
//         <div className="upload-upper">
//             <div className='upload-image'>
//                 <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/telescope%201.png?alt=media&token=7d247101-2f40-420b-9792-4a4f1aa28ed7" />
//             </div>
//                 <div className='upload-right'>
//                 <div className='upload-title'>
//                     {upload.name}
//                 </div>
//                 <div className='upload-recordedby'>
//                     <p className='recordedBy'>
//                         Created 
//                     </p>
//                     <p className='university'>
//                         {upload.requestedTimestamp.toLocaleString()}
//                     </p>
//                 </div>
//             </div>
//         </div>
//         {/* <div className="upload-center">
//             {DoubleField("Reviewers", upload.reviewerCount.toString())}
//             {DoubleField("Filesize", upload.filesize)}
//             {DoubleField("Rated", upload.rating.toString())}
//         </div> */}
//         <div className='upload-bottom'>
//             <div className='view-specification'>
//                 View Specification
//             </div>
//             <div className='download'>
//                 <Button trackClick={() => props.openModal(upload)} name='Download' />
//                     {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-download-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /></button> */}
//             </div>
//         </div>
//     </div>
// )}

const UploadCard = (props: { walletAddress: string, upload: IUpload, refresh: () => void, setPopupState: (state: PopupState) => void, setDataPopupState: (upload: IUpload | undefined) => void }) => {
   const [loading, setLoading] = useState(false)
    const submit = async () => {
        if (loading) {
            return;
        }
        setLoading(true)
        const functions = getFunctions();
        const callableReturnMessage = httpsCallable(functions, 'submitUpload');

        const uploadRequest = {
          walletAddress: props.walletAddress,
          uploadId: props.upload.id
        }
        try {
            const response = await callableReturnMessage(uploadRequest);
        } catch(e) {

        }
        props.refresh()
        setLoading(false)
    }

    const comp = (() => { 
        switch (props.upload.state) {
            case UploadState.Draft:
                return (
                <>
                    <div className="upload-edit-button-line">
                        <button className="upload-edit-button" onClick={() => props.setPopupState({open: true, upload: props.upload })}>Edit Specification</button>
                        <button className="upload-edit-button" onClick={() => props.setDataPopupState(props.upload)}>Upload Data</button>
                    </div>
                    <Button className="upload-submit-button" disabled={loading} loading={loading} name="Submit for Review"  trackClick={submit} />
                         
{/* </Button> */}
                </>
                    )
            case UploadState.ReviewPending:
                return (
                <>
                    <button disabled className="upload-pending-button" onClick={submit}>Under Review</button>
                </>
                    )
            case UploadState.Refused:
                return <div></div>
    }})()

    return (
        <div className="upload">
        <div className="upload-upper">
            <div className='upload-image'>
                <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/telescope%201.png?alt=media&token=7d247101-2f40-420b-9792-4a4f1aa28ed7" />
            </div>
                <div className='upload-right'>
                <div className='upload-title'>
                    {props.upload.name}
                </div>
                <div className='upload-recordedby'>
                    <p className='recordedBy'>
                        Created 
                    </p>
                    <p className='university'>
                        {new Date(props.upload.requestedTimestamp).toLocaleString()}
                    </p>
                </div>
            </div>
        </div>
        {/* <div className="upload-center">
            {DoubleField("Reviewers", upload.reviewerCount.toString())}
            {DoubleField("Filesize", upload.filesize)}
            {DoubleField("Rated", upload.rating.toString())}
        </div> */}
        <div className='upload-bottom'>
            {comp}
        </div>
    </div>
    )
}

const section = (walletAddress: string, name: string, uploads: IUpload[], refresh: () => void, setPopupState: (state: PopupState) => void, setDataPopupState: (upload: IUpload | undefined) => void) => {
    return (<div className="uploadSection">
        <div className="uploadSectionTitle">{name}</div>
        <div className="uploadContainer">
            {uploads.length === 0 && <p style={{color: "gray"}}>This section is currently empty.</p>}
            {uploads.map(u => <UploadCard walletAddress={walletAddress} upload={u} refresh={refresh} setPopupState={setPopupState} setDataPopupState={setDataPopupState} />)}
        </div>
    </div>);
}

const getUploads = async (walletAddress: string) => {
    const functions = getFunctions();
    const callableReturnMessage = httpsCallable(functions, 'uploads');
    // try {
    const response = await callableReturnMessage({ walletAddress: walletAddress });
    const data = response.data as IUpload[];
    return data.sort(u => u.requestedTimestamp).reverse();

    // } catch (e) {
    //     setError("Invalid Claim Id")
    // }
}

interface PopupState {
    open: boolean;
    upload?: IUpload;
}

export const MyUploads = () => {
    const [popupState, setPopupState] = useState<PopupState>({ open: false, upload: undefined});
    const [dataPopupState, setDataPopupState] = useState<IUpload | undefined>(undefined);
    
    const [uploads, setUploads] = useState<IUpload[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { address, isConnected } = useAppKitAccount();
    // const [open, setOpen] = useState(false);
    

    useEffect(() => {
       const set = async () => {const a = await getUploads(address); console.error(a); setUploads(a);}
       if (isConnected) {
            set()
       }
       if (refresh) {
        setRefresh(false)
       }
    }, [isConnected, refresh])

    return (
    <WalletGated>
         <MyPopup modal nested onClose={() => setDataPopupState(undefined)} open={dataPopupState !== undefined} closeOnDocumentClick position="top left">
            {close => (
            <div>
                {(dataPopupState && <UploadDataPopup connectionSettings={{port: 21, host: uploadHost, username: dataPopupState.id, password: dataPopupState.ftpPassword}} />)}
            </div>
            )} 
        </MyPopup>
        <div className="title-line">
            <h1 className='page-title' style={{marginBottom: 0}}>Upload to <span className="gradient">DataStore</span></h1>
            <button className="upload-button" onClick={() => setPopupState({ open: true })}>Upload Dataset</button>
        </div>
        {section(address, "Draft", uploads.filter(u => u.state === UploadState.Draft), () => setRefresh(true), setPopupState, setDataPopupState)}
        {section(address, "In Review", uploads.filter(u => u.state === UploadState.ReviewPending), () => setRefresh(true), setPopupState, setDataPopupState)}
        {section(address, "Published", uploads.filter(u => u.state === UploadState.Uploaded), () => setRefresh(true), setPopupState, setDataPopupState)}
        {section(address, "Rejected", uploads.filter(u => u.state === UploadState.Refused), () => setRefresh(true), setPopupState, setDataPopupState)}
          <MyPopup modal nested onClose={() => setPopupState({ open: false })} open={popupState.open} closeOnDocumentClick position="top left">
        {close => (
        // <div>

            <SpecificationPopup edit={true} close={close} walletAddress={address} refresh={() => setRefresh(true)} upload={popupState.upload} />
            // <DownloadPopup upload={upload} />
        // </div>
        )} 
    </MyPopup>
        </WalletGated>)
}