import react, { useEffect, useState } from 'react';
import './UploadPopup.css';
import { IDataset, ISpecification } from '../DataStoreExplorer/DataStoreExplorer';
import { Button } from '../functions/Button';
import { Input } from '../functions/Input';
import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { CircularProgress } from "@mui/material";

import { initializeApp } from "firebase/app";
import { UploadState } from '../MyUploads/MyUploads';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-teETwqkUxOM27zgawA8AsnQC2yvcraI",
    authDomain: "starchain-app.firebaseapp.com",
    projectId: "starchain-app",
    storageBucket: "starchain-app.appspot.com",
    messagingSenderId: "173972643442",
    appId: "1:173972643442:web:fdd9ce39f0cd8670d02ceb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export interface IUploadBase {
    walletAddress: string;
    name: string;
    shortDescription: string;
    sourceDescription: string;
    contentsDescription: string;
    usageDescription: string;
}

  
export interface IUpload extends IUploadBase {
    id: string;
    tags: string[]
    requestedTimestamp: number;
    state: UploadState
    ftpPassword: string;
  }
  
const UploadDialog = (props: { walletAddress: string, close: () => void, refresh: () => void, selectedUpload?: IUpload, edit?: boolean, specification?: ISpecification }) => {
    const [upload, setUpload] = useState(
        {
            name: props.selectedUpload?.name ?? props.specification?.name ?? "",
            shortDescription: props.selectedUpload?.shortDescription ?? props.specification?.shortDescription ?? "",
            sourceDescription: props.selectedUpload?.sourceDescription ?? props.specification?.sourceDescription ?? "",
            contentsDescription: props.selectedUpload?.contentsDescription ?? props.specification?.contentsDescription ?? "",
            usageDescription: props.selectedUpload?.usageDescription ?? props.specification?.usageDescription ?? ""
        }
    )

    const [loading, isLoading] = useState(false)

    const createUpload = async () => {
        if (loading) {
            return
        }
        isLoading(true)
          const functions = getFunctions();
          const callableReturnMessage = httpsCallable(functions, 'upload');

          const uploadRequest: IUploadBase = {
            ...upload,
            walletAddress: props.walletAddress
          }

              const response = await callableReturnMessage(uploadRequest);

              props.close()
              props.refresh()
              isLoading(false)
    }

    const editUpload = async () => {
        if (loading) {
            return
        }
        isLoading(true)
        const functions = getFunctions();
        const callableReturnMessage = httpsCallable(functions, 'editUpload');

            const uploadRequest: IUploadBase  & { uploadId: string } = {
                ...upload,
                walletAddress: props.walletAddress,
                uploadId: props.selectedUpload!.id
            }

            const response = await callableReturnMessage(uploadRequest);

            props.close()
            props.refresh()
            isLoading(false)
  }

    return (
        <div className='upload-popup-card'>
            <Input disabled={(props.specification && !props.edit)} value={upload.name} name="Dataset Name*" overwriteStyle={{marginBottom: "1.1rem"}} trackValue={v => setUpload({...upload, name: v })} />
            <Input disabled={(props.specification && !props.edit)} area value={upload.shortDescription} name="Short Description" overwriteStyle={{marginBottom: "1.1rem", paddingTop: "0.9rem", paddingBottom: "3rem"}} trackValue={v => setUpload({...upload, shortDescription: v })} />
            {/* <Input name="Folder Name" /> */}
            <Input disabled={(props.specification && !props.edit)} value={upload.sourceDescription} name="Dataset Source*" overwriteStyle={{marginBottom: "1.1rem"}} trackValue={v => setUpload({...upload, sourceDescription: v })} />
            <Input  disabled={(props.specification && !props.edit)}area value={upload.contentsDescription} name="Dataset Contents" overwriteStyle={{marginBottom: "1.1rem", paddingTop: "0.9rem", paddingBottom: "3rem"}} trackValue={v => setUpload({...upload, contentsDescription: v })} />
            <Input disabled={(props.specification && !props.edit)} area value={upload.usageDescription} name="Dataset Usage" overwriteStyle={{marginBottom: "1.1rem", paddingTop: "0.9rem", paddingBottom: "3rem"}} trackValue={v => setUpload({...upload, usageDescription: v })} />
            {props.edit &&<div style={{marginTop: "0.8rem", display: "flex", justifyContent: "space-between"}}>
            {loading && <CircularProgress style={{color: "white", marginRight: "0.4rem"}} size="1.25rem" />}
                <Button disabled={loading} trackClick={() => { if (!loading) { props.close()} }} secondary name='Cancel Upload'  />
                {(props.selectedUpload ? <Button disabled={loading} trackClick={editUpload} name='Edit Upload' /> : <Button trackClick={createUpload} name='Start Upload' />)}
            </div>}
        </div>
    )
}

const Troubleshooting = () => {
    return (
        <div className='upload-popup-card'>
        <div className='upload-row upload-row-fixed upload-popup-card-title'>
            Troubleshooting
        </div>
        <div className='upload-row upload-popup-card-lower'>
            <div className='upload-popup-card-text'>
                Learn how to use a FTP-Client to connect to a Data-Server.
            </div>
            <div className='upload'>
                <Button name={"Get Help"} trackClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")} />
            {/* <button className='upload-button' style={{cursor: "pointer"}} onClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")}><div>Get Help</div> */}
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-upload-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
            {/* </button> */}
        </div>
        </div>
    </div>
    )
}

export const SpecificationPopup = (props: {walletAddress: string, close: () => void, refresh: () => void, upload?: IUpload, edit?: boolean, specification?: ISpecification }) => {
    return (
            <div className='upload-popup'>
                <div className='upload-popup-title'>
                    {props.upload || props.specification ? (props.edit ? "Edit Specification" : "View Specification") : "Specify Dataset"}
                </div>
                <UploadDialog specification={props.specification} close={props.close} walletAddress={props.walletAddress} refresh={props.refresh} selectedUpload={props.upload} edit={props.edit} />
                {/* <div className='upload-popup-lower'>
                    <Browserupload uploadUrl={props.dataset.uploadUrl} />
                    <Troubleshooting />
                </div> */}
            </div>
    )
}
