import { CSSProperties, useState } from "react";
import styles from './Button.module.css'
import { CircularProgress } from "@mui/material";

interface ButtonProps {
    disabled?: boolean
    name: string;
    imgURL?: string;
    trackClick?: () => void;
    secondary?: boolean;
    loading?: boolean;
    className?: string;
}

export const Button = (props: ButtonProps) => {
    return (
        <>
            <button disabled={props.disabled} className={styles.button + " " + (props.secondary ? styles.secondary : "") + " " + (props.className ? props.className : "")} onClick={() => props.trackClick ? props.trackClick() : null}>
                {props.loading && <CircularProgress style={{color: "white", marginRight: "0.4rem"}} size="1.25rem" />}
                {props.name}
                {props.imgURL && <img src={props.imgURL} /> }</button>
        </>
    );
}