import react from 'react';
import './UploadDataPopup.css';
import { IDataset } from '../DataStoreExplorer/DataStoreExplorer';
import { Button } from '../functions/Button';

const FTPUpload = (props: { connectionSettings: FTPConnectionSettings }) => {
    return (
    <div className='ftp-upload-data upload-data-popup-card'>
        <div className='upload-data-row upload-data-row-fixed upload-data-popup-card-title'>
            FTP-Access
        </div>
        <div className='upload-data-row upload-data-row-fixed '>
            <div className='upload-data-row-left'>
                FTP-Host
            </div>
            <div className='upload-data-row-right'>
                {props.connectionSettings.host}
            </div>
        </div>
        {/* <div className='upload-data-row upload-data-row-fixed'>
            <div className='upload-data-row-left'>
                FTP-Hostname
            </div>
            <div className='upload-data-row-right'>
                {props.connectionSettings.ftpHostName}
            </div>
        </div> */}
        <div className='upload-data-row upload-data-row-fixed '>
            <div className='upload-data-row-left'>
                FTP-Username
            </div>
            <div className='upload-data-row-right'>
                {props.connectionSettings.username}
            </div>
        </div>
        <div className='upload-data-row upload-data-row-fixed'>
            <div className='upload-data-row-left'>
                FTP-Password
            </div>
            <div className='upload-data-row-right'>
                {props.connectionSettings.password}
            </div>
        </div>
        <div className='upload-data-row upload-data-row-fixed'>
            <div className='upload-data-row-left'>
                FTP-Port
            </div>
            <div className='upload-data-row-right'>
                {props.connectionSettings.port}
            </div>
        </div>
        <div className='upload-data-row upload-data-row-fixed'>
            <div className='upload-data-row-left'>
                Recommended FTP-Clients
            </div>
            <div className='upload-data-row-right'>
                <a href="https://www.smartftp.com/en-us/" className='purple-link' target={"_blank"}>SmartFTP</a>  or  <a className='purple-link' href='https://filezilla-project.org/' target={"_blank"}>FileZilla</a>
            </div>
        </div>
    </div>
    )
}

// const Browserupload-data = (props: { upload-dataUrl: string | undefined }) => {
//     return (
//         <div className='upload-data-popup-card'>
//             <div className='upload-data-row upload-data-row-fixed upload-data-popup-card-title'>
//                 Browser upload-data (easy)
//             </div>
//             <div className='upload-data-row upload-data-popup-card-lower'>
//                 <div className='upload-data-popup-card-text'>
//                     In diesem Artikel erfahren Sie, wie Sie eine Verbindung zu einem FTP-Konto herstellen
//                 </div>
//                 <div className='upload-data'>
//                     <a href={props.upload-dataUrl} upload-data={props.upload-dataUrl}>
                        
//                         <Button name={"upload-data"}  />
//                 {/* <button className={'upload-data-button ' + (props.upload-dataUrl ? '' : 'upload-data-button-inactive')}>
//                 <div>upload-data</div>
//                 {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-upload-data-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
//                 {/* </button> */}
//                 </a>
//             </div>
//             </div>
//         </div>
//     )
// }

const HowToUpload = () => {
    return (
        <div className='upload-data-popup-card'>
        <div className='upload-data-row upload-data-row-fixed upload-data-popup-card-title'>
            Upload Guide
        </div>
        <div className='upload-data-row upload-data-popup-card-lower'>
            <div className='upload-data-popup-card-text'>
                Connect yourself to the FTP-Server above and simply drag your files into the root directory. After your upload is finished you can submit it for review. 
            </div>
            {/* <div className='upload-data'> */}
                {/* <Button name={"Get Help"} trackClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")} /> */}
            {/* <button className='upload-data-button' style={{cursor: "pointer"}} onClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")}><div>Get Help</div> */}
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-upload-data-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
            {/* </button> */}
        {/* </div> */}
        </div>
    </div>
    )
}

const Troubleshooting = () => {
    return (
        <div className='upload-data-popup-card'>
        <div className='upload-data-row upload-data-row-fixed upload-data-popup-card-title'>
            Troubleshooting
        </div>
        <div className='upload-data-row upload-data-popup-card-lower'>
            <div className='upload-data-popup-card-text'>
                Learn how to use a FTP-Client to connect to a File Server.
            </div>
            <div className='upload-data'>
                <Button name={"Get Help"} trackClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")} />
            {/* <button className='upload-data-button' style={{cursor: "pointer"}} onClick={() => window.open("https://filezillapro.com/docs/v3/basic-usage-instructions/connecting-to-a-server/", "_blank")}><div>Get Help</div> */}
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/starchain-app.appspot.com/o/noun-upload-data-7075268%201.svg?alt=media&token=642ceb89-fce9-45a2-813f-6b4cbce72be1" /> */}
            {/* </button> */}
        </div>
        </div>
    </div>
    )
}

export interface FTPConnectionSettings {
    host: string;
    username: string;
    password: string;
    port: number;
}

export const UploadDataPopup = (props: { connectionSettings: FTPConnectionSettings }) => {
    return (
        <div className='upload-data-popup'>
            <div className='upload-data-popup-title'>
                {/* Upload-data: {props.connectionSettings.name} */}
                Data Upload
            </div>
            <FTPUpload connectionSettings={props.connectionSettings} />
            <div className='upload-data-popup-lower'>
                <HowToUpload />
                {/* <Browserupload-data upload-dataUrl={props.connectionSettings.upload-dataUrl} /> */}
                <Troubleshooting />
            </div>
        </div>
    )
}
