import { useAppKitAccount } from '@reown/appkit/react';
import React from 'react';
import { WalletConnect } from '../WalletConnect/WalletConntect';
import styles from './Referral.module.css';


export const WalletGated = (props: { children: React.ReactNode; }) => {
    const { address, isConnected } = useAppKitAccount();
    return !isConnected ? (
        <div
            className={styles.referralLinkSection}
            style={{display: "flex", justifyContent: "center"}}
            // style={{ backgroundImage: `url(${background})` }}
        >
            <div>
            <div className={styles.referralLinkTitle}>Connect Your Wallet</div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
                    <WalletConnect />
                </div>
            </div>
        </div>
    ) : (<div>
        {props.children}
    </div>);
};
