import React, { ReactNode, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import './Rewards.css';
import { initializeApp } from "firebase/app";
import { IReward } from '../../Interfaces/IReward';
import IUser from '../../Interfaces/IUser';
import moneySVG from '../../icons/money.svg'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-teETwqkUxOM27zgawA8AsnQC2yvcraI",
    authDomain: "starchain-app.firebaseapp.com",
    projectId: "starchain-app",
    storageBucket: "starchain-app.appspot.com",
    messagingSenderId: "173972643442",
    appId: "1:173972643442:web:fdd9ce39f0cd8670d02ceb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const callFirebaseFunction = event => {
//     const functions = getFunctions();
//     const callableReturnMessage = httpsCallable(functions, 'returnMessage');

//     callableReturnMessage().then((result) => {
//       console.log(result.data.output);
//     }).catch((error) => {
//       console.log(`error: ${JSON.stringify(error)}`);
//     });
// }

interface IGetRewardsResponse {
    user: IUser;
    rewards: IReward[]
}

type SetClaimContext = React.Dispatch<React.SetStateAction<IGetRewardsResponse | null>>
type SetError = React.Dispatch<React.SetStateAction<string | null>>

interface ClaimIdInputProps {
    setClaimContext: SetClaimContext
    setError: SetError
    error: string | null;
}

const getRewards = async (claimId: string, error: string | null, setError: SetError, setClaimContext: SetClaimContext) => {
    const functions = getFunctions();
    const callableReturnMessage = httpsCallable(functions, 'getRewards');
    try {
        const response = await callableReturnMessage({ claimId: claimId });
        if (error) {
            setError(null);
        }
        const data = response.data as IGetRewardsResponse;
        localStorage.setItem("claimId", claimId)
        console.error(data)
        setClaimContext(data);

    } catch (e) {
        setError("Invalid Claim Id")
    }
}

const ClaimIdInput = (props: ClaimIdInputProps) => {
    const [claimId, setClaimId] = useState("");

    return (
        <>
            <div className="claimId-input">
                <input placeholder='Enter your Claim Id' className="input-field" type='text' onChange={(v) => setClaimId(v.target.value)} />
                <div className="primary-button" onClick={() => getRewards(claimId, props.error, props.setError, props.setClaimContext)}>Claim</div>
            </div>
            {props.error && <p>{props.error}</p>}
        </>
    );
}

interface IRewardListProps {
    rewards: IReward[];
}

const RewardTitle = () => {
    return <h1 className='page-title'>My <span className="gradient">Rewards</span></h1>
}

const RewardList = (props: IRewardListProps) => {
    const rewardItem = (reward: IReward) => {
        return (
            <div className="reward">
                <div className="reward-upper">
                    <div className='reward-image'>
                        <img src={moneySVG} />
                    </div>
                    <div className='reward-right'>
                        <div className='reward-title'>
                            200x STRC
                        </div>
                        <div className='reward-recordedby'>
                            <p className='claimable-until'>
                                Claimable Until
                            </p>
                            <p className='claimable-until-date'>
                                December 12, 2024
                            </p>
                        </div>
                    </div>
                </div>
                <div className="reward-center">
                    <div className="reward-double-field ">
                        <div className='reward-upperText'>
                            USD Value
                        </div>
                        <div className='reward-lowerText'>
                            $110
                        </div>
                    </div>
                    <div className='filler-line' />
                    <div className="reward-double-field ">
                        <div className='reward-upperText'>
                            Type
                        </div>
                        <div className='reward-lowerText reward-lowerText-small'>
                            Ecosystem Supporters
                        </div>
                    </div>
                </div>
                <div className='reward-bottom'>
                    <div className='claim'>
                        <button className='claim-button'><div>Claim</div></button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {props.rewards.map(rewardItem)}
        </div>
    )
}

interface Sub {
    children: ReactNode
}

const Container = (obj: Sub) => {
    return <div style={{width: "25rem", margin: "0 auto"}}>
      {obj.children}
    </div>
  }
  
export const RewardClaim = () => {
    const [error, setError] = useState<string | null>(null);

    const [claimContext, setClaimContext] = useState<IGetRewardsResponse | null>(null);

    const claimId = localStorage.getItem("claimId");

    if (claimId && !claimContext) {
        getRewards(claimId, error, setError, setClaimContext)
    }
    if (claimContext) {
        console.error(claimContext)
    }
    const section = claimContext?.rewards ?
        (
            <div>
                <RewardTitle />
                <RewardList rewards={claimContext.rewards} />
            </div>
        )
        : (
            <div>
                <Container>
                    <ClaimIdInput setError={setError} error={error} setClaimContext={setClaimContext} />
                </Container>
            </div>
        );

        console.error("claim")

        return section;
}
